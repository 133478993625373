import React, {useEffect, useState} from 'react';
import axios from "axios";

const LoginForm = (props) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [networkError, setNetworkError] = useState(false);

  const Login = (user, pass) => {
      props.setProcessing(true);
      return axios
        .post(props.url + "/login",
          {
              "user": user,
              "pass": pass,
          }
        )
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            console.log('Success login');
            props.token(response.data.auth);
            props.setProcessing(false);
            return response.data;
          } else {
            props.setProcessing(false);
            return null;
          }
        })
        .catch((error) => {
          props.setProcessing(false);
          console.log("LOGIN ERROR");
          if(error.response.status === 401) {
            setLoginError(true);
            setTimeout(() => {
              setLoginError(false);
            }, 4000);
            return;
          }
          if(error.response) {
            console.log(error.response.status);
            console.log(error.response.headers);
            console.log(error.response.data);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('Error', error.message);
          }
          console.log(error.config);
          setNetworkError(true);
          setTimeout(() => {
             setNetworkError(false);
          }, 4000);
        });
    }

  const handleLogin = async () => {
    setUsernameError(username === '' );
    setPasswordError(password === '' );

    if (username && password) {
      Login(username,password);
    }
  };

  useEffect( () => {
    setUsernameError(username === '' );
    setPasswordError(password === '' );
    console.log("useEffect - username: ", username);
    console.log("useEffect - password: ", password);
  }, [password, username]);

  return (
  <>
    <div className={'loginForm'}>
      <img src={"logo.png"} alt={"AI-DESK logo"} />
      <div>
        <input
          type="text"
          className={"user " + (usernameError ? "error" : "")}
          placeholder={"Username"}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div>
        <input
          type="password"
          className={"pass " + (passwordError ? "error" : "")}
          placeholder={"Password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div>
        <button onClick={handleLogin}>Login</button>
      </div>
    </div>

    <div className={"message error" + (loginError ? "":" unvisible")}>
      <p>The username or password is incorrect</p>
    </div>
    <div className={"message error" + (networkError ? "":" unvisible")}>
      <p>A network error has occurred. Wait a few minutes and try again.</p>
    </div>

  </>
  );
};

export default LoginForm;
