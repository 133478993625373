import React, {useState, useRef, useEffect} from 'react';
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicroscope, faMicrophoneSlash, faTrash, faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import "./app.scss"
import LoginForm from "./loginform";

const URL = "https://cheppy.westeurope.cloudapp.azure.com";
const postIdleTimeout = 60000; //  milliseconds (1m)
const sessionTimeout = 3600000; //  milliseconds (1h)

function timestampToTime(timestamp) {
  // Create a new Date object from the timestamp (in milliseconds)
  const date = new Date(timestamp);

  // Extract hours, minutes, and seconds
  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');
  const seconds = date.getUTCSeconds().toString().padStart(2, '0');

  // Return the time in HH:MM:SS format
  return `${hours}:${minutes}:${seconds}`;
}

function App() {

  const langs = {
    0: {'code':"de-DE", 'person': "de-DE-AmalaNeural", 'gender': "Female"},
    1: {'code':"de-DE", 'person': "de-DE-BerndNeural", 'gender': "Male"},
    2: {'code':"en-US", 'person': "en-US-AshleyNeural", 'gender': "Female"},
    3: {'code':"en-US", 'person': "en-US-BrandonNeural", 'gender': "Male"},
    4: {'code':"es-ES", 'person': "es-ES-LiaNeural", 'gender': "Female"},
    5: {'code':"es-ES", 'person': "es-ES-AlvaroNeural", 'gender': "Male"},
    6: {'code':"fr-FR", 'person': "fr-FR-BrigitteNeural", 'gender': "Female"},
    7: {'code':"fr-FR", 'person': "fr-FR-AlainNeural", 'gender': "Male"},
    8: {'code':"hu-HU", 'person': "hu-HU-NoemiNeural", 'gender': "Female"},
    9: {'code':"hu-HU", 'person': "hu-HU-TamasNeural", 'gender': "Male"},
    10: {'code':"it-IT", 'person': "it-IT-ElsaNeural", 'gender': "Female"},
    11: {'code':"it-IT", 'person': "it-IT-DiegoNeural1", 'gender': "Male"},
  };

  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('isLoggedIn') === 'true');
  const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken') === null
                                                                ? '' : localStorage.getItem('accessToken'));

  const errorHandler = (error) => {
    console.log("NETWORK ERROR");
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    console.log(error.config);
    Logout();
  }
  const Logout = () => {
    setAccessToken('');
    localStorage.clear();
  }

  const [domains,setDomains] = useState(localStorage.getItem('domains') === null
                                                              ? {} : JSON.parse(localStorage.getItem('domains')));

  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState("");
  const [processing, setProcessing] = useState(false);
  const [domain, setDomain] = useState(-1);
  const [person, setPerson] = useState("hu-HU-TamasNeural");
  const [detectedLang, setDetectedLang] = useState(navigator.language || navigator.userLanguage);
  const [listening, setListening] = useState(false);
  const [reading, setReading] = useState(false);
  const [blink, setBlink] = useState('');
  const [domainErrorMessage, setDomainErrorMessage] = useState(false);
  const [domainError, setDomainError] = useState(null);

  const questionInput = useRef(0);

  let sdk = require("microsoft-cognitiveservices-speech-sdk");
  const speechConfig = sdk.SpeechConfig.fromSubscription("db2f1bc14df9424590ccfec349bc6baf", "westeurope");

  speechConfig.speechSynthesisVoiceName = person;
  if(person !== 'autodetect') {
    speechConfig.speechRecognitionLanguage = person.substring(0, 5);
  }

  const getDomains = () => {
    return axios
      .get(URL,
        {
          headers: {
            "x-access-token": accessToken,
            "content-type": "application/json"
          },
          params: {
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          // console.log('Return axios getDomains');
          // console.log(JSON.stringify(response.data));
          localStorage.setItem('domains',JSON.stringify(response.data));
          setDomains(response.data);
          return response.data;
        } else {
          Logout();
          return null;
        }
      })
      .catch((error) => {
        errorHandler(error);
      });
  }
  const postIdle = () => {
    if(!localStorage.getItem('lastIdle'))
      localStorage.setItem('lastIdle', Date.now().toString());
    const elapsedTime = (Date.now() - parseInt(localStorage.getItem('lastIdle'), 10)) ;
    console.log("last sent idle request: ", timestampToTime(elapsedTime) );

    if(elapsedTime > sessionTimeout) {
      Logout();
      return;
    }

    if(elapsedTime > postIdleTimeout ) {
      return axios
        .post(URL + "/idle",
          {},
          {
            headers: {
              "x-access-token": accessToken,
              "content-type": "application/json"
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            localStorage.setItem('lastIdle', Date.now().toString());
            console.log('Idle request sent');
            return response.data;
          } else {
            Logout();
            return null;
          }
        })
        .catch((error) => {
          errorHandler(error);
        });
    }
  }
  const getAnswer = (str, collection, email) => {
    return axios
      .post(URL + (email ? '/email' : ''),
          {
            "collection": collection,
            "question": str,
          },
          {
            headers: {
              "x-access-token": accessToken,
              "content-type": "application/json"
            },
          }
      )
      .then((response) => {
        if (response.status === 200) {
          console.log('Return axios');
          console.log(response.data)
          return response.data;
        } else {
          Logout();
          return null;
        }
      })
      .catch((error) => {
        errorHandler(error);
      });
  }

  const handleGetAnswer = (email) => {
    console.log('Click to put' + (email ? 'Email' : '') + 'Question button');
    if(domain === -1) {
      console.log("Domain is not selected");
      setDomainError(true);
      setDomainErrorMessage(true)
      return false;
    }
    if(question === '') {
      console.log("Empty question");
      return false;
    }
    setQuestion(questionInput.current.value);

    if(question === null) // only the initial state is null
      return;
    setProcessing(true);
    getAnswer(question, domain, email).then(
      (res) => {
        setAnswer(res);
        setProcessing(false);
      }
    );
  }

  useEffect( () => {
    console.log("useEffect - domain: ", domain);
    if(domainError !== null)
      setDomainError(domain < 0);
  }, [domain]);

  useEffect( () => {
    console.log("useEffect - domainError: ", domainError);
    if(domainError) {
      setDomainErrorMessage(true);
    }
  }, [domainError]);

  useEffect( () => {
    console.log("useEffect - domainErrorMessage: ", domainErrorMessage);
    if(domainErrorMessage)
      setTimeout(() => {setDomainErrorMessage(false)},2000);
  }, [domainErrorMessage]);

  useEffect( () => {
//    console.log("useEffect - isLoggedIn: ", isLoggedIn);
    localStorage.setItem('isLoggedIn', (isLoggedIn ? "true" : "false"));
    if(isLoggedIn)
      localStorage.setItem('lastIdle', Date.now().toString());
  }, [isLoggedIn]);

  useEffect( () => {
//    console.log("useEffect - accessToken: ", accessToken);
    localStorage.setItem('accessToken', accessToken);
    setIsLoggedIn(!!accessToken);
  }, [accessToken]);

  useEffect( () => {
//    console.log("useEffect - person: ", person);
    speechConfig.speechSynthesisVoiceName = person;
    if(person !== 'autodetect') {
      speechConfig.speechRecognitionLanguage = person.substring(0,5);
    }
  }, [person]);

  useEffect( () => {
    console.log('useEffect - reading: ', reading);

    if(reading) {
      let player = new sdk.SpeakerAudioDestination();
      let audioConfig  = sdk.AudioConfig.fromSpeakerOutput(player);
      let synthesizer = new sdk.SpeechSynthesizer(speechConfig, audioConfig);
      synthesizer.speakTextAsync(answer,
        function (result) {
          if (result.reason === sdk.ResultReason.SynthesizingAudioCompleted) {
            console.log("synthesis finished.");
          } else {
            console.error("Speech synthesis canceled, " + result.errorDetails +
              "\nDid you set the speech resource key and region values?");
          }
          synthesizer.close();
          synthesizer = null;
          setReading(false);
        },
        function (err) {
          console.trace("err - " + err);
          synthesizer.close();
          synthesizer = null;
          setReading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reading]);

  useEffect( () => {
    console.log("useEffect - detectedLang: ", detectedLang);
    for (const key in langs) {
      if(langs[key].code === detectedLang) {
        setPerson(langs[key].person);
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detectedLang]);

  useEffect( () => {
    // console.log("useEffect - blink: ", blink);
    if(blink !== '') {
      setTimeout(() => {setBlink('')}, 1000);
    }
  }, [blink]);

  useEffect( () => {
    console.log('useEffect - listening: ', listening);
    if(!listening) {
      console.log('Listening stopped');
    }
    else {
      console.log('Listening');

      let audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
      let speechRecognizer;
      if(person === 'autodetect') {
        console.log('Recognition language: ', speechConfig.speechRecognitionLanguage );
        let autoDetectSourceLanguageConfig
          = sdk.AutoDetectSourceLanguageConfig.fromLanguages(["en-US", "hu-HU"]);
        speechRecognizer = sdk.SpeechRecognizer.FromConfig(speechConfig, autoDetectSourceLanguageConfig, audioConfig);
        speechRecognizer.recognizeOnceAsync((result) => {
            let languageDetectionResult = sdk.AutoDetectSourceLanguageResult.fromResult(result);
            setDetectedLang(languageDetectionResult.language);
          },
          {});
      }
      else {
        console.log('Recognition language: ', speechConfig.speechRecognitionLanguage );
        speechRecognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);
      }

      speechRecognizer.recognizeOnceAsync(result => {
        switch (result.reason) {
          case sdk.ResultReason.RecognizedSpeech:
            console.log(`RECOGNIZED: Text=${result.text}`);
            setQuestion((question.length > 0 && listening ? question + '\n' : '') + result.text)
            setBlink('blink-success');
            setListening(false);
            break;
          case sdk.ResultReason.NoMatch:
            console.log("NOMATCH: Speech could not be recognized.");
            setBlink('blink-error');
            setListening(false);
            break;
          case sdk.ResultReason.Canceled:
            const cancellation = sdk.CancellationDetails.fromResult(result);
            console.log(`CANCELED: Reason=${cancellation.reason}`);

            if (cancellation.reason === sdk.CancellationReason.Error) {
              console.log(`CANCELED: ErrorCode=${cancellation.ErrorCode}`);
              console.log(`CANCELED: ErrorDetails=${cancellation.errorDetails}`);
              console.log("CANCELED: Did you set the speech resource key and region values?");
            }
            setBlink('blink-error');
            setListening(false);
            break;
          default:
            console.log(`SOME ERROR: Reason=${result.reason}`);
        }
        speechRecognizer.close();
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }  }, [listening]);

  if(isLoggedIn && Object.keys(domains).length === 0)
    getDomains();

  return <>
  {!isLoggedIn && <LoginForm token={setAccessToken} url={URL} setProcessing={setProcessing} />}
  {isLoggedIn && <>
    <div className={"wrapper"} onClick={postIdle}>
      <img src={"logo.png"} alt={"AI-DESK logo"} />
      <FontAwesomeIcon
        icon={faArrowUpRightFromSquare}
        className={"exit"}
        onClick={Logout}
      />
      <h1 onClick={() => {getDomains()}}>Cheppy</h1>

      <div className={"cols"}>
          <div className={"inputs"}>
            <div className={"line"}>
              <textarea ref={questionInput}
                     className={"question " + blink}
                     placeholder={"Ask! \n(Type a question or \nuse the microphone button \nto recognize your speech)"}
                     rows={10}
                     value={!!question ? question : ''}
                     onChange={(e)=> {
                       postIdle();
                       setQuestion((question.length > 0 && listening ? question + '\n' : '') + e.target.value)
                     }}
              />
            </div>
            <div className={"line"}>
              <FontAwesomeIcon icon={faTrash}
                               className={"clear"}
                               onClick={() => {
                                 console.log('Click to Clear button');
                                 setQuestion('');
                               }}
              />
              <FontAwesomeIcon icon={listening ? faMicroscope : faMicrophoneSlash}
                               className={"listen"}
                               onClick={() => {
                                 console.log('Click to Listen button');
                                 setListening(!listening);
                               }}
              />
            </div>
            <div className={"selectDomainWrapper" + (domainError ? " error" : "")}>
              <select
                className={"selectDomain"}
                onChange={(e) => {
                  postIdle();
                  setDomain(e.target.value)}}
                defaultValue={"6"}
                >
                <option value="-1">Choose knowledge base</option>
                {Object.keys(domains).length > 0 && Object.entries(domains).map(([key, value],i) =>
                  <option value={value} key={i}>{value}</option>
                )}
              </select>
            </div>
            <button
              className={"putQuestion"}
              disabled={question === ''}
              onClick={() => {handleGetAnswer(false)}}
            >Search</button>
            <button
              className={"putQuestion email"}
              disabled={question === ''}
              onClick={() => {handleGetAnswer(true);}}
            >Get email answer</button>
          </div>
          <div className={"results"}>
            <div className={"question_text hidden"}>
              {/*{question}*/}
            </div>
            <div className={"answer_text"}>
              <div className={"answer_content"}>
              {answer.split('\n').map((v,i)=> (
                <p key={i}>{v}</p>
              ))}
              <br/>
              </div>
            </div>
            <div>
              <button
                className={"read "}
                disabled={answer === null || answer.length === 0}
                onClick={() => {
                  console.log('Read button click');
                  setReading(!reading);
                }}
              >Read</button>

            </div>
          </div>
        </div>

      <div className={"selectPersonWrapper"}>
        <select
          className={"selectPerson"}
          onChange={(e) => {setPerson(e.target.value)}}
          value={person !== '' ? person : 'hu-HU-TamasNeural'}
        >
          <option value="autodetect">Autodetect language</option>
          {Object.entries(langs).map( (lang, i) =>
             <option value={lang[1].person} key={lang[1].code + '-' + i}>
               Recognition language: {lang[1].code}; Reading by: {lang[1].person} ({lang[1].gender})
             </option>
            )}
        </select>
      </div>
    </div>

    <div className={"message error" + (domainErrorMessage ? "":" unvisible")}>
      <p>Choose a knowledge base before submitting a question</p>
    </div>

    <div className={"bg"}></div>
  </>}
    <div className={"loading " + (processing?"show":"")}>
      <div className="lds-ring">
        <div/>
        <div/>
        <div/>
        <div/>
      </div>
    </div>
  </>
}
export default App
